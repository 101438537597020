import React, { useState } from 'react';
import PropTypes from 'prop-types';

const QuestionAnswer = ({
  isOpen,
  item: { question, answer, more },
  onClick,
}) => (
  <div className="mt-6 border-t border-gray-200 pt-6">
    <div>
      <dt className="text-lg leading-7">
        <button
          type="button"
          className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
          onClick={onClick}
        >
          <span className="font-medium text-gray-900">{question}</span>
          <span className="ml-6 h-7 flex items-center">
            <svg
              className={`${
                isOpen ? '-rotate-180' : 'rotate-0'
              } h-6 w-6 transform transition ease-in-out duration-200`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </button>
      </dt>
      <dd
        className={`${
          isOpen ? 'max-h-52' : 'max-h-0'
        } mt-2 pr-12 transition-all ease-in-out duration-100 overflow-hidden`}
      >
        <p className="text-base leading-6 text-gray-500">{answer}</p>
        {more && (
          <a href={more.linkURL} className="inline-block mt-1">
            <span className="rounded bg-blue-50 px-2.5 py-1 text-xs font-bold text-blue-500 tracking-wide uppercase">
              {more.label}&nbsp;&rarr;
            </span>
          </a>
        )}
      </dd>
    </div>
  </div>
);

QuestionAnswer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    more: PropTypes.object.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const FAQ = ({ title, items }) => {
  const [openedQuestion, setOpenedQuestion] = useState();
  const handleExpandQuestion = (index) =>
    setOpenedQuestion(index !== openedQuestion ? index : undefined);
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            {title}
          </h2>

          {items.map((item, i) => (
            <QuestionAnswer
              key={item.id}
              isOpen={openedQuestion === i}
              item={item}
              onClick={() => handleExpandQuestion(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

FAQ.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

FAQ.defaultProps = {};

export default FAQ;
