import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import StarRatings from './StarRatings';

const Intro = ({ image }) => {
  const [shouldPlayVideo, setShouldPlayVideo] = useState(false);
  const handlePlayClick = () => setShouldPlayVideo(true);
  return (
    <section className="mt-20 sm:mt-0">
      <div className="flex flex-col lg:flex-row bg-gray-800">
        <div className="relative w-full lg:w-1/2 flex-shrink-0 lg:flex lg:items-center">
          <div
            className={`absolute top-0 left-0 w-full h-full z-10 transition-opacity duration-700 ease-in-out ${
              shouldPlayVideo ? 'opacity-0 pointer-events-none' : 'opacity-100'
            }`}
            onClick={handlePlayClick}
            onKeyDown={handlePlayClick}
            role="button"
            tabIndex={0}
          >
            <Img
              fluid={image.childImageSharp.fluid}
              alt={image.name}
              className="object-cover w-full h-full"
            />
          </div>
          <div
            id="responsiveVideoWrapper"
            className="relative h-0 pb-fluid-video flex-1"
          >
            <iframe
              title="Easy Tag ‑ GTM &amp; Data Layer - Introduction"
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/C1YmG0BGeaM?enablejsapi=1&controls=0"
              // src={
              //   shouldPlayVideo
              //     ? 'https://www.youtube.com/embed/C1YmG0BGeaM?autoplay=1'
              //     : 'https://www.youtube.com/embed/C1YmG0BGeaM'
              // }
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex-shrink-0 px-4 py-12  sm:px-6 lg:px-12 lg:py-4 lg:flex lg:items-center">
          <div className="">
            <div className="text-base font-semibold tracking-wider text-gray-300 uppercase leading-6">
              Get started today
            </div>{' '}
            <h2 className="mt-2 text-3xl font-extrabold tracking-tight text-white leading-9 sm:text-4xl sm:leading-10">
              Easy Tag ‑ GTM &amp; Data Layer
            </h2>{' '}
            <StarRatings
              inverse
              className="mt-3"
              separatorColor="divide-gray-500"
            />
            <p className="mt-3 text-lg text-gray-300 leading-7">
              Install Google Tag Manager, select the marketing tags you need,
              and hit play on growing your Shopify business while the rest of
              the world is hitting pause.
            </p>{' '}
            <div className="mt-8">
              <div className="inline-flex shadow rounded-md">
                <a
                  href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent leading-6 rounded-md hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  Install the app
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 ml-3 -mr-1 text-gray-400"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />{' '}
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Intro.propTypes = {
  image: PropTypes.object.isRequired,
};

Intro.defaultProps = {};

export default Intro;
