import React from 'react';
// import PropTypes from 'prop-types';
// import Img from 'gatsby-image';

const Pricing = () => (
  <div id="pricing" className="py-16 mt-20 overflow-hidden bg-gray-50 lg:py-24 sm:mt-0">
    <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <div className="relative">
        <h2 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 leading-8 sm:text-4xl sm:leading-10">
          What's My Investment
        </h2>{' '}
      </div>{' '}
      <div className="relative max-w-lg mx-auto mt-12 grid gap-5 lg:grid-cols-7 lg:max-w-none">
        <div className="max-w-lg mx-auto mt-10 lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
          <div className="relative z-10 rounded-lg shadow-xl">
            <div className="absolute inset-0 border-2 border-indigo-600 rounded-lg pointer-events-none"></div>
            <div className="absolute inset-x-0 top-0 transform translate-y-px">
              <div className="flex justify-center transform -translate-y-1/2">
                <span className="inline-flex px-4 py-1 text-sm font-semibold tracking-wider text-white uppercase bg-indigo-600 rounded-full leading-5">
                  Limited-Time Offer
                </span>
              </div>
            </div>
            <div className="px-6 pt-12 pb-10 bg-white rounded-t-lg">
              <div>
                <h3
                  id="tier-growth"
                  className="text-3xl font-semibold text-center text-gray-900 leading-9 sm:-mx-6"
                >
                  Pre-built Tags &amp; Data Layer
                </h3>
                <div className="flex items-center justify-center mt-4">
                  <span className="flex items-start px-3 text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">
                    <span className="mt-2 mr-2 text-4xl font-medium">$</span>
                    <span className="font-extrabold">349</span>
                  </span>
                  {/* <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-600">
                    -57%
                  </span> */}
                </div>
                {/* <div className="flex items-center justify-center">
                  <span className="text-2xl font-medium text-gray-500 leading-8 line-through">
                    $347
                  </span>
                </div> */}
                <div className="flex items-center justify-center text-xl text-gray-400">
                  one-time charge
                </div>
              </div>
            </div>
            <div className="px-6 pt-10 pb-8 border-t-2 border-gray-100 rounded-b-lg bg-gray-50 sm:px-10 sm:py-10">
              <ul>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Pre-built Data Layer
                  </p>
                </li>
                <li className="flex items-start mt-4">
                  <div className="flex-shrink-0">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Over 50 ready-made marketing tags
                  </p>
                </li>
                <li className="flex items-start mt-4">
                  <div className="flex-shrink-0">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Track transactions on your "Thank you" page
                  </p>
                </li>
                <li className="flex items-start mt-4">
                  <div className="flex-shrink-0">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Google Analytics (GA4) implementation
                  </p>
                </li>
                <li className="flex items-start mt-4">
                  <div className="flex-shrink-0">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>{' '}
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Technical tag support
                  </p>
                </li>
              </ul>
              <hr className="my-4" />{' '}
              {/* <ul>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>{' '}
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Unlimited widgets
                  </p>
                </li>{' '}
                <li className="flex items-start mt-4">
                  <div className="flex-shrink-0">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>{' '}
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Complete widget customization
                  </p>
                </li>{' '}
                <li className="flex items-start mt-4">
                  <div className="flex-shrink-0">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>{' '}
                  <p className="ml-3 text-base font-medium text-gray-500 leading-6">
                    Priority support
                  </p>
                </li>
              </ul>{' '} */}
              <div className="mt-10">
                <div className="rounded-lg shadow-md">
                  <a
                    href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-describedby="tier-growth"
                    className="block w-full px-6 py-4 text-xl font-medium font-bold text-center text-white bg-indigo-600 border border-transparent rounded-lg cursor-pointer leading-6 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                  >
                    Install the app
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Pricing.propTypes = {};

Pricing.defaultProps = {};

export default Pricing;
