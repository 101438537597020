import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Benefits = ({ images }) => (
  <div className="py-16 mt-20 overflow-hidden bg-gray-50 lg:py-24 sm:mt-0">
    <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-screen-xl">
      <svg
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        className="absolute hidden lg:block left-full transform -translate-x-1/2 -translate-y-1/4"
      >
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              fill="currentColor"
              className="text-gray-200"
            />
          </pattern>
        </defs>{' '}
        <rect
          width="404"
          height="784"
          fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
        />
      </svg>
      <div className="relative">
        <h2 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 leading-8 sm:text-4xl sm:leading-10">
          Google Tag Manager for Shopify implemented correctly.
          <br />
          <span className="text-indigo-600">With a single click.</span>
        </h2>{' '}
        <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-500 leading-7">
          Building an e-commerce store is hard, but setting up Google Tag
          Manager doesn't have to be. With Easy Tag for Shopify you can now
          install GTM and your data layer with a single click, and choose from
          our library of 50+ pre-built marketing tags. Setting up your marketing
          has never been so easy.
        </p>
      </div>{' '}
      <div className="relative max-w-lg mx-auto mt-12 grid gap-5 lg:grid-cols-3 lg:max-w-none">
        <div className="flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
          <div className="flex-shrink-0">
            {/* <img
              src="/purchase.svg"
              alt=""
              className="object-cover w-full h-48"
              // style="background-color: rgb(242, 242, 242);"
            /> */}
            <Img fluid={images[0].childImageSharp.fluid} alt={images[0].name} />
          </div>{' '}
          <div className="flex flex-col justify-between flex-1 p-6 bg-white">
            <div className="flex-1">
              <p className="text-sm font-medium text-indigo-600 leading-5">
                Just one click
              </p>{' '}
              <span className="block">
                <h3 className="mt-2 text-xl font-semibold text-gray-900 leading-7">
                  Quick &amp; Easy to install
                </h3>{' '}
                <p className="mt-3 text-base text-gray-500 leading-6">
                  Easy Tag provides a simple Google Tag Manager and Data Layer
                  installation. We've automated the process of installing over
                  50 marketing tags.
                </p>
              </span>
            </div>
          </div>
        </div>{' '}
        <div className="flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
          <div className="flex-shrink-0">
            {/* <img
              src="/smiley.svg"
              alt=""
              className="object-cover w-full h-48"
              // style="background-color: rgb(242, 242, 242);"
            /> */}
            <Img fluid={images[1].childImageSharp.fluid} alt={images[1].name} />
          </div>{' '}
          <div className="flex flex-col justify-between flex-1 p-6 bg-white">
            <div className="flex-1">
              <p className="text-sm font-medium text-indigo-600 leading-5">
                Trust your data
              </p>{' '}
              <span className="block">
                <h3 className="mt-2 text-xl font-semibold text-gray-900 leading-7">
                  Improve data accuracy
                </h3>{' '}
                <p className="mt-3 text-base text-gray-500 leading-6">
                  Spent more of your time worrying about the things that matter,
                  and we'll make sure your data has been gathered correctly.
                </p>
              </span>
            </div>
          </div>
        </div>{' '}
        <div className="flex flex-col overflow-hidden bg-white rounded-lg shadow-lg">
          <div className="flex-shrink-0">
            {/* <img
              src="/new_ideas.svg"
              alt=""
              className="object-cover w-full h-48 bg-white"
              // style="background-color: rgb(242, 242, 242);"
            /> */}
            <Img fluid={images[2].childImageSharp.fluid} alt={images[2].name} />
          </div>{' '}
          <div className="flex flex-col justify-between flex-1 p-6">
            <div className="flex-1">
              <p className="text-sm font-medium text-indigo-600 leading-5">
                We're here to help
              </p>{' '}
              <span className="block">
                <h3 className="mt-2 text-xl font-semibold text-gray-900 leading-7">
                  Friendly Technical Support
                </h3>{' '}
                <p className="mt-3 text-base text-gray-500 leading-6">
                  Our team of tag experts is here to help you and ensure you get
                  the most of your tag manager implementation. Don't be a
                  stranger.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Benefits.propTypes = {
  images: PropTypes.array.isRequired,
};

Benefits.defaultProps = {};

export default Benefits;
