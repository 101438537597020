import React from 'react';
import PropTypes from 'prop-types';
import Integrations from '../components/Integrations';
import Benefits from '../components/Benefits';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';
import Faq from '../components/FAQ';
import Intro from '../components/Intro';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Testimonial from '../components/Testimonial';
import RecentBlogPosts from '../components/RecentBlogs';

const IndexPage = ({ data }) => (
  <Layout>
    {/* 1-Click Google Tag Manager + Data Layer installer for Shopify & 50+ tags */}
    {/*  */}
    {/* <SEO title="Pre-built Data Layer for GTM | 40+ marketing tags" /> */}
    {/* <SEO title="GTM & Data Layer app for Shopify | 50+ tags" /> */}
    <Seo title="Google Tag Manager & Data Layer App for Shopify" />
    <Hero image={data.imgTagInstallHero} />
    <Benefits
      images={[
        data.imgBenefitEasyToInstall,
        data.imgBenefitImproveDataAccuracy,
        data.imgBenefitFriendlyTechnicalSupport,
      ]}
    />
    <Intro image={data.imgVideoThumbnail} />
    {/* <Integration image={data.imgVideoThumbnail} /> */}
    {/* <Blocks /> */}
    <Integrations />
    <Testimonial
      name="Sam Franklin"
      logo={data.imgOriginalKettlebell}
      quote="Easy Tag saved me days! My data is clean, my tags are firing correctly, and I can focus on selling."
      photo={data.imgSamFranklin}
    />
    <RecentBlogPosts />
    <Faq
      title="Frequently asked questions"
      items={[
        {
          id: 1,
          question: 'Do you support Google Analytics 4 (GA4)?',
          answer: 'Yes we do. Let us know if you need more details on this.',
          more: {
            label: 'Learn more',
            linkURL: '/shopify-google-analytics-4',
          },
        },
        {
          id: 2,
          question: 'Can Easy Tag be installed by a non-technical user?',
          answer:
            'Absolutely. Easy Tag is designed for everybody and no technical knowledge is required.',
        },
        {
          id: 3,
          question:
            'Do I need a Google Tag Manager account/container in order to use Easy Tag?',
          answer:
            "Yes you do, but don't worry, it's a simple registration process like in all other Google products. Reach out and we'll send a tutorial on how to do this.",
        },

        {
          id: 4,
          question:
            'I already have some tags in my container. Can I still use Easy Tag?',
          answer:
            "Yes you can. We recommend our support team to look into your container before you sync to ensure there are no issues. Don't worry it is a part of the service.",
        },
        {
          id: 5,
          question: 'I need a tag which is not on your list.',
          answer:
            "No problem. Just contact our support team and ask. We're constantly adding new tags based on what customers want for their Shopify stores.",
        },
        {
          id: 6,
          question: 'Do you offer technical support?',
          answer:
            'Yes. We offer technical advise on how to set up Google Tag Manager on Shopify with Easy Tag and if you have any problems, we are here to help.',
        },
        {
          id: 7,
          question: 'Do you work over weekends?',
          answer: 'No, we handle all inquiries during standard business hours.',
        },
      ]}
    />
    <Pricing />
    {/* <CTA subtitile="Learn why companies use Easy Tag to manage their marketing tags." /> */}
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    imgTagInstallHero: PropTypes.object.isRequired,
    imgOriginalKettlebell: PropTypes.object.isRequired,
    imgBenefitEasyToInstall: PropTypes.object.isRequired,
    imgBenefitImproveDataAccuracy: PropTypes.object.isRequired,
    imgBenefitFriendlyTechnicalSupport: PropTypes.object.isRequired,
    imgVideoThumbnail: PropTypes.object.isRequired,
    imgSamFranklin: PropTypes.object.isRequired,
  }).isRequired,
};

export default IndexPage;

export const data = graphql`
  query IndexPageQuery {
    imgTagInstallHero: file(name: { eq: "taginstall-hero-image" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgOriginalKettlebell: file(name: { eq: "original-kettlebell" }) {
      name
      childImageSharp {
        fixed(width: 140, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    imgBenefitEasyToInstall: file(name: { eq: "benefit-easy-to-install" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgBenefitImproveDataAccuracy: file(
      name: { eq: "benefit-improve-data-accuracy" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgBenefitFriendlyTechnicalSupport: file(
      name: { eq: "benefit-friendly-technical-support" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgVideoThumbnail: file(name: { eq: "video-thumbnail" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imgSamFranklin: file(name: { eq: "sam-franklin" }) {
      name
      childImageSharp {
        fixed(height: 40, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;
