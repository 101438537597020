import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import StarRatings from './StarRatings';

const Hero = ({ image }) => (
  <section>
    <div className="relative">
      <div className="lg:relative">
        <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:pt-24 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 leading-10 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
              {/* Implement{' '}
              <span className="text-indigo-600">Google Tag Manager</span> with a
              single click */}
              1-Click GTM installation for{' '}
              <span className="text-indigo-600">Shopify</span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Easy Tag takes out the complexity of setting up Google Tag Manager
              and implementing your marketing tags, allowing you to focus on the
              things that matter.
            </p>{' '}
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="shadow rounded-md">
                <a
                  href="https://apps.shopify.com/google-tag-manager-installer?surface_type=website"
                  className="flex items-center justify-center w-full px-8 py-3 text-base font-medium font-bold text-white bg-indigo-600 border border-transparent shadow-2xl leading-6 rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Install the app
                </a>
              </div>{' '}
              {/* <div className="mx-2 shadow rounded-md">
                <span className="flex items-center justify-center w-full px-8 py-3 mt-4 text-base font-medium text-gray-600 bg-white border border-transparent shadow-2xl cursor-pointer leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10 hover:bg-gray-100 sm:mt-0">
                  See the widget
                </span>
              </div> */}
            </div>{' '}
            <StarRatings />
            {/* <div className="flex flex-col mt-2 text-gray-400">
              <div className="py-1">
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline w-6 h-6 text-green-400 align-text-bottom"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                100% free
              </div>{' '}
              <div className="py-1">
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline w-6 h-6 text-green-400 align-text-bottom"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Unlimited feedback widgets
              </div>{' '}
              <div className="py-1">
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline w-6 h-6 text-green-400 align-text-bottom"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Unlimited pages
              </div>
            </div> */}
          </div>
        </div>{' '}
        <div className="relative w-full h-auto sm:h-72 md:h-auto lg:absolute lg:inset-y-0 lg:top-4 lg:right-0 lg:w-1/2 lg:h-full">
          {/* <img
            src="/new_hero_img.png"
            alt="Feedback widget plus dashboard"
            className="absolute inset-0 object-cover object-left w-full h-full"
          /> */}
          <Img
            fluid={image.childImageSharp.fluid}
            alt={image.name}
            className="hero-image h-full w-full"
          />
        </div>
      </div>
    </div>
  </section>
);

Hero.propTypes = {
  image: PropTypes.object.isRequired,
};

Hero.defaultProps = {};

export default Hero;
